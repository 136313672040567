export const IMAGES={
    logo:require("../assets/Logo/Logo01.png"),
    eye:require("../assets/Logo/eye.png"),
    eye_close:require("../assets/Logo/eye_close.png"),
    correct:require("../assets/Logo/correct.png"),
    cancel:require("../assets/Logo/cancel.png"),
    login:require("../assets/Logo/profile-user.png"),
    search:require("../assets/Logo/search.png"),
    searchb:require("../assets/Logo/searchb.png"),
    filter:require("../assets/Logo/filter.png"),
    menu1:require("../assets/Logo/menu.png"),
    bread:require("../assets/animal_logo/newborn.png"),
    purchased:require("../assets/animal_logo/cash.png"),
    menu:require("../assets/Logo/menu.png"),
    back:require("../assets/Logo/previous.png"),
    home:require("../assets/Logo/home.png"),
    addAnimal:require("../assets/Logo/pet-care.png"),
    med:require("../assets/Logo/first-aid-kit.png"),
    weight:require("../assets/Logo/weight-scale.png"),
    tag:require("../assets/Add/price-tag.png"),
    name:require("../assets/Add/name.png"),
    mom:require("../assets/Add/bird.png"),
    scale:require("../assets/Add/weight.png"),
    money:require("../assets/Add/money.png"),
    age:require("../assets/Add/age.png"),
    // vacc:require("../assets/Add/vaccine.png"),
    thirty:require("../assets/Add/speed-limit.png"),
    dog:require("../assets/Add/breed.png"),
    disease:require("../assets/Add/disease.png"),
    medicines:require("../assets/Add/medicines.png"),
    dropper:require("../assets/Add/dropper.png"),
    withdraw:require("../assets/Add/atm.png"),
    logout:require("../assets/Logo/logout.png"),
    calender:require("../assets/Logo/calendar-2.png"),
    male:require("../assets/animal_logo/male.png"),
    female:require("../assets/animal_logo/female.png"),
    bell:require("../assets/Logo/bell.png"),
    herd:require("../assets/Logo/dog.png"),
    // money:require("../assets/Logo/money.png"),
    herdhelp:require("../assets/Logo/Herd-Help-Logo.png"),
    log:require("../assets/Logo/user.png"),
    sign:require("../assets/Logo/add-user.png"),
    update:require("../assets/Logo/refresh.png"),
    aler:require("../assets/Add/alert.png"),
    clock:require("../assets/Add/clock.png"),
    mark:require("../assets/Add/mark.png"),
    HH:require("../assets/Logo/logo.png"),
    setting:require("../assets/Logo/settings.png"),
    file:require("../assets/Logo/file.png"),
    heart:require("../assets/Logo/heart.png"),
    delet:require("../assets/Logo/delete.png"),
    parents:require("../assets/Logo/family.png"),
    down:require("../assets/Logo/down.png"),
    right:require("../assets/Logo/arrow-right.png"),
    subs:require("../assets/Logo/subscription.png"),
    rightone:require("../assets/Logo/right.png"),
    paid:require("../assets/Logo/paid.png"),
    card:require("../assets/Logo/credit-cards.png"),
    sack:require("../assets/Logo/sack.png"),
    playstore:require("../assets/playstore.png"),
    appstore:require("../assets/app-store.png"),
    menuios:require("../assets/Logo/menuios.png"),
    close:require("../assets/Logo/close.png"),
    close2:require("../assets/Logo/close2.png"),
    closegif:require("../assets/Logo/closegif.gif"),
    cow:require("../assets/animal/cow.png"),
    // dog:require("../assets/animal/dog.png"),
    goat:require("../assets/animal/goat.png"),
    horse:require("../assets/animal/horse.png"),
    pig:require("../assets/animal/pig.png"),
    rabbit:require("../assets/animal/rabbit.png"),
    sheep:require("../assets/animal/sheep.png"),
    plus:require("../assets/Add/plus.png"),
    male1:require("../assets/Logo/male.png"),
    female1:require("../assets/Logo/female.png"),
    bred:require("../assets/Logo/bred.png"),
    flag:require("../assets/Logo/flag.png"),
    // med:require("../assets/Logo/first-aid-kit.png"),
    gain:require("../assets/Logo/gain.png"),
    img1:require("../assets/Logo/splash.png"),
    img2:require("../assets/Logo/log1.png"),
    setImg:require("../assets/Logo/Combined-Shape.png"),
    chart:require("../assets/Logo/trend.png"),
    plus1:require("../assets/Add/plus1.png"),
    sideback:require("../assets/Logo/play-arrow.png"),
    lbs:require("../assets/Add/lb.png"),
    splash : require("../assets/splash.png"),
    scangif : require("../assets/scanner.gif"),
    add : require("../assets/Logo/add.png"),
    coin : require("../assets/Logo/coin.png"),
    issue : require("../assets/Logo/exclamation.png"),
    what : require("../assets/Logo/question.png"),
    health : require("../assets/Home/Health.jpg"),
    alert : require("../assets/Home/Alert.jpg"),
    babies : require("../assets/Home/Babies.jpg"),
    finance : require("../assets/Home/Finance.jpg"),
    profit : require("../assets/Home/Profit.jpg"),
    weight1 : require("../assets/Home/Weight.jpg"),
    about : require("../assets/Home/About.jpg"),
    play:require("../assets/play.png"),
    app:require("../assets/app.png"),






}